<template>
  <div id="app">

  </div>
</template>

<script>
export default {
    name: "Webapp",
    components: {
        
    }
}
</script>
    
<style>

</style>